export const changeMode = (mode)=>{
    return (dispatch) =>{
        dispatch({
            type: 'update',
            payload: mode
        })
    }
}
export const changeColor = (color)=>{
    return (dispatch) =>{
        dispatch({
            type: 'change',
            payload: color
        })
    }
}
export const changeUser = (user)=>{
    return (dispatch) =>{
        dispatch({
            type: 'changeUser',
            payload: user
        })
    }
}

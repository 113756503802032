import React from "react";
import { Link } from "react-router-dom";
import ProjectDialog from "./ProjectDialog";

const ProjectCard = ({ project,setLoading }) => {
    var t = new Date(parseInt(project.times));
    var dat = t.getFullYear() + "/" + t.getMonth() + "/" + t.getDay() 
    var tim =t.getHours()+":"+t.getMinutes();
  return (
    <div className="col-sm-4 p-4">
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{project.title}</h5>
        <div className="row container-fluid">
          <h6 className="card-subtitle mb-2 text-muted col-sm-6">Created at - </h6>
          <h6 className="card-subtitle mb-2 text-muted col-sm-6">{dat} {tim}</h6>
        </div>
        <p className="card-text">{project.type}</p>
        <div className="container-fluid row">
            <Link to={`./${encodeURIComponent(project.secret)}`} className="btn btn-dark">
              View Project
            </Link>
            {/* <ProjectDialog setLoading={setLoading} title={project.title} project={project}></ProjectDialog> */}
        </div>
      </div>
    </div>
    </div>
  );
};

export default ProjectCard;

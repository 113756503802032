import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import Singleblock from "../components/Singleblock";
import { useNavigate, useNavigation, useParams, useRoutes } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Globals from "../Globals";
import Loader from "../components/Loader";
import { Alert, Button, InputAdornment, Snackbar, TextField } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import { ContentCopy } from "@mui/icons-material";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Projectview({ logout, user }) {
  let { project } = useParams();
  project = decodeURIComponent(project);
  // console.log(project);
  const globals = new Globals();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const regerateClientSecret = async () => {
    axios
      .post(`${globals.getBackendApi()}/regerateProjectClientSecret`, {
        pname: projects.title,
        pid: projects.id,
      })
      .then((res) => {
        // console.log(res);
        setLoading(true);
        navigate("/projects")
      });
  };
  const [secretKey, setSecretKey] = useState("")
  const [blocks, setBlocks] = useState([])

  useEffect(() => {
    function work(){
      axios
        .post(`${globals.getBackendApi()}/verifyprojectid`, {
          secretCode: project,
          user: user,
        })
        .then((res) => {
            console.log(res.data.results);
          if (res.data.results.length === 0) {
            setError(true);
            setLoading(false);
          } else {
            axios.post(`${globals.getBackendApi()}/getblocks`, {
              "clientSecret": project,
              "projectName": res.data.results[0].title
            }).then((res)=>{
              setBlocks(res.data)
              setLoading(false);
            })
            setProjects(res.data.results[0]);
            setSecretKey(res.data.results[0].secret)
          }
        });
    };
    work();
    return work;
  }, []);
 
  useEffect(() => {
    function work(){
      axios
        .post(`${globals.getBackendApi()}/verifyprojectid`, {
          secretCode: project,
          user: user,
        })
        .then((res) => {
            console.log(res.data.results);
          if (res.data.results.length === 0) {
            setError(true);
            setLoading(false);
          } else {
            axios.post(`${globals.getBackendApi()}/getblocks`, {
              "clientSecret": project,
              "projectName": res.data.results[0].title
            }).then((blocks)=>{
              setBlocks(blocks.data)
              setLoading(false);
            })
            setProjects(res.data.results[0]);
            setSecretKey(res.data.results[0].secret)
          }
        });
    };
    work();
    return work;
  }, [loading]);


  const [openCopied, setOpenCopied] = useState(false);

  const handleSnackClick = () => {
    setOpenCopied(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenCopied(false);
  };

  if (loading) {
    return <Loader></Loader>;
  } else {
    if (error) {
      console.log(error)
      return (
        <Box sx={{ display: "flex" }}>
          <Header
            DrawerHeader={DrawerHeader}
            title={"Error"}
            logout={logout}
          ></Header>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <Typography variant="h2">No Such Project Found</Typography>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <Snackbar
            open={openCopied}
            autoHideDuration={6000}
            onClose={handleSnackClose}
          >
            <Alert
              onClose={handleSnackClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Copied to Clipboard
            </Alert>
          </Snackbar>
          ;
          <Header
            DrawerHeader={DrawerHeader}
            title={projects.title}
            logout={logout}
          ></Header>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />

            <div className="container-fluid row p-4">
              <div className="col-sm-8">
                <CopyToClipboard text={secretKey} onCopy={handleSnackClick}>
                  <TextField
                    value={secretKey}
                    fullWidth
                    label="Client Secret Key"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ContentCopy className="cursor-pointer" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </CopyToClipboard>
              </div>

              <div className="col-sm-4 text-align-right">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={regerateClientSecret}
                >
                  Regenerate Api Key
                </Button>
              </div>
            </div>

            <div className="container-fluid row">
                    {
                     blocks.map((value, index)=>{
                      return <Singleblock block={value} key={index}></Singleblock>
                     }) 
                    }
            </div>
          </Box>
        </Box>
      );
    }
  }
}

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import Globals from "../../Globals";

const Postallblocks = () => {
  const globals = new Globals();
  const [value, setValue] = useState(1);

  const [nodecode, setNodecode] = useState(`
async function fetchBlocks(){
  try {
    var clientSecret = 'Your Client Secret id from the project page';
    var projectName = 'Name of the project.';
    const response = await fetch("${globals.getBackendApi()}/getblocks/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "clientSecret": clientSecret,
          "projectName": projectName,
          "data": {your data in JSON format},
          "user":"Your login email"
      }),
    });
    const result = await response.json();
    console.log(result);
  } catch (error) {
    console.error("Error:", error);
  }
}

fetchBlocks();
`);

const [python, setPython] = useState(`
import requests
clientSecret = 'Your Client Secret id from the project page'
projectName = 'Name of the project.'
x = requests.post("${globals.getBackendApi()}/getblocks/",json={
    "clientSecret": clientSecret,
    "projectName": projectName,
    "data": {your data in JSON format},
    "user":"Your login email"
})
data = eval(x.content)
print(data)

`)

const [axios, setAxios] = useState(`
var clientSecret = 'Your Client Secret id from the project page';
var projectName = 'Name of the project.';
axios
    .post(url, {
        "clientSecret": clientSecret,
        "projectName": projectName,
        "data": {your data in JSON format},
        "user":"Your login email"
    })
    .then((res) => {
        console.log(res);
    });
`)

  const [javaCode, setJavaCode] = useState(`public void POSTReq() throws IOException {
  String clientSecret = "Your Client Secret id from the project page";
  String projectName = "Name of the project.";
  String email = "Your Email.";
  final String messageContent = "{
    \\"clientSecret\\": clientSecret,\\n
    \\"projectName\\": projectName,\\n
    \\"data\\": {your data in JSON format}\\n,
    \\"user\\":email\\n
}";
  System.out.println(messageContent);
  String url = "${globals.getBackendApi()}/getblocks/";
  URL urlObj = new URL(url);
  HttpURLConnection postCon = (HttpURLConnection) urlObj.openConnection();
  postCon.setRequestMethod("POST");
  postCon.setRequestProperty("Content-Type", "application/json");
  postCon.setDoOutput(true);
  OutputStream osObj = postCon.getOutputStream();
  osObj.write(messageContent.getBytes());
  osObj.flush();
  osObj.close();
  int respCode = postCon.getResponseCode();
  System.out.println("Response from the server is: \\n");
  System.out.println("The POST Request Response Code :  " + respCode);
  System.out.println("The POST Request Response Message : " + postCon.getResponseMessage());
  if (respCode == 200) {
    InputStreamReader irObj = new InputStreamReader(postCon.getInputStream());
    BufferedReader br = new BufferedReader(irObj);
    String input = null;
    StringBuffer sb = new StringBuffer();
    while ((input = br.readLine()) != null) {
      sb.append(input);
    }
    br.close();
    postCon.disconnect();
    System.out.println(sb.toString());
  } else {
    System.out.println("POST Request did not work.");
  }
}`);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div className="step container get-api">
      
      <div className="px-4">
        <h5>
          <span className="post-span">POST</span> Create a new Block
        </h5>
      </div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Java Script" />
            <Tab label="Axios - JS" />
            <Tab label="Python" />
            <Tab label="Java" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Editor
            value={nodecode}
            onValueChange={(code) => {}}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
        <Editor
            value={axios}
            onValueChange={(code) => {}}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
        <Editor
            value={python}
            onValueChange={(code) => {}}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Editor
            value={javaCode}
            onValueChange={(code) => {}}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
            }}
          />
        </TabPanel>
      </Box>
    </div>
  );
};

export default Postallblocks;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Header from '../components/Header';
import Comingsoon from '../components/Comingsoon';


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Party({logout}) {
 
  return (
    <Box sx={{ display: 'flex' }}>
      <Header DrawerHeader={DrawerHeader} title={"Party"} logout={logout}></Header>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Comingsoon></Comingsoon>
      </Box>
    </Box>
  );
}
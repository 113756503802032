import React from 'react'
import { HashLoader } from 'react-spinners';

const Loader = () => {
  return (
    <div className='text-center vertical-center'>
      
      <HashLoader
        color="#000"
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      
    </div>
  )
}

export default Loader
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import Pageheading from "../components/Pageheading";
import { Link } from "react-router-dom";
import Getallblocks from "../components/documentation/Getallblocks";
import Postallblocks from "../components/documentation/Postallblocks";
import CreateBlock from "../components/documentation/CreateBlock";
import PostFindAblock from "../components/documentation/PostFindAblock";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Docs({ logout }) {
  return (
    <Box sx={{ display: "flex" }}>
      <Header
        DrawerHeader={DrawerHeader}
        title={"Documentation"}
        logout={logout}
      ></Header>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        <Pageheading title={"Documentation"}></Pageheading>
        <div className="conatainer-fluid ">
          <Typography variant="h2">Lets Get Started</Typography>
          <br></br>
          <br></br>
          <div className="step container create-project">
            <h4>1. Create a new project.</h4>
            <Typography variant="p">
              You can headover to the{" "}
              <Link to="/projects">
                <b>Project Section</b>
              </Link>{" "}
              in order to create a new project.
            </Typography>
            <br></br>
            <small>
              A project is set of all the record and every block that is stored
              or are to be stored.
            </small>
          </div>
          <br></br>
          <div className="step container get-api">
            <h4>2. Get the API keys.</h4>
            <Typography variant="p">
              API key is available in the view project page. you can copy your
              secret client key so that it could be placed in the code.
            </Typography>
          </div>

          <br></br>
          <>
            <Getallblocks></Getallblocks>
          </>

          <>
            <Postallblocks></Postallblocks>
          </>

          <>
            <CreateBlock></CreateBlock>
          </>
          
          <>
            <PostFindAblock></PostFindAblock>
          </>
        </div>
      </Box>
    </Box>
  );
}

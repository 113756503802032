import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { MenuItem, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import Globals from "../Globals";
import { useCookies } from "react-cookie";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dialogbox({ title = "Add a project", setLoading }) {
  const [open, setOpen] = React.useState(false);
  const [projectTitle, setProjectTitle] = React.useState("");
  const [projectType, setProjectType] = React.useState("OTHER");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [cookies, setCoookie, removeCookie] = useCookies(["user"]);
  
  const handleClose = () => {
    setOpen(false);
  };
  const globals = new Globals();
  const createProject = async ()=>{
    axios.post(`${globals.getBackendApi()}/addproject`,{
        title:projectTitle,
        manager:cookies.email,
        type: projectType
    }).then((res)=>{
        // console.log(res);
        handleClose()
        setLoading(true);
    })
  }

  const techstack = [
    {
      value: 'WEB APP',
      label: 'Web Application',
    },
    {
      value: 'REACT WEB APP',
      label: 'React Web Application',
    },
    {
      value: 'ANDROID WITH JAVA',
      label: 'Android with JAVA',
    },
    {
      value: 'ANDROID WITH KOTLIN',
      label: 'Android with Kotlin',
    },
    {
      value: 'FLUTTER',
      label: 'Flutter',
    },
    {
      value: 'REACT NATIVE',
      label: 'React Native',
    },
    {
      value: 'PYTHON APPLICATION',
      label: 'Python Application',
    },
    {
      value: 'GO LANG APPLICATION',
      label: 'Go Lang Application',
    },
    {
      value: 'SWIFT APPLICATION',
      label: 'Swift Application',
    },
    {
      value: 'OTHER',
      label: 'Other',
    },
  ];

  const headerColor = useSelector(state => state.color);
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        {title}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }} style={{background:headerColor}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            <Button autoFocus color="inherit" onClick={createProject}>
              Submit
            </Button>
          </Toolbar>
        </AppBar>
        <div className="p-4 container-fluid row">
          <TextField
            id="outlined-basic"
            label="title"
            variant="outlined"
            className="col-sm-12 my-2"
            value={projectTitle}
            onChange={(e)=>{setProjectTitle(e.target.value)}}
          />
          <TextField
            id="outlined-select-currency"
            select
            label="Type"
            helperText="Please select your an application type"
            className="col-sm-12 my-2"
            value={projectType}
            onChange={(e)=>{setProjectType(e.target.value)}}
            required
          >
            {techstack.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <button className="btn btn-dark col-sm-3 my-4" onClick={createProject}>Create Project</button>
        </div>
      </Dialog>
    </div>
  );
}


import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Header from '../components/Header';
import Pageheading from '../components/Pageheading';
import { Link } from 'react-router-dom';
import * as blocks from '../assets/blocks.png'
import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Globals from '../Globals';
import Loader from '../components/Loader';


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Home({logout}) {

  const [cookies, setCoookie, removeCookie] = useCookies(["user"]);
  const user = cookies.email;
  const [projectCount, setProjectCount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [thirstyDaysAmount, setThirstyDaysAmount] = useState(0);
  const globals = new Globals();
  
  useEffect(() => {
    function work(){
      // /getbilling
      axios
        .post(`${globals.getBackendApi()}/getbilling`, {
          email: user,
        })
        .then((res) => {
          var tda = 0;
          res.data.map((value, index) => {
            value.time = new Date(parseInt(value.time));
            if ((new Date() - value.time) / 8.64e7 < 30) {
              tda += parseFloat(value.bill);
            }
          });
          setThirstyDaysAmount(tda);
          // console.log(`${globals.getBackendApi()}/getuserdata`)
          axios.post(`${globals.getBackendApi()}/getprojects`, {
            manager: user
          }).then((res) => {
              setProjectCount(res.data.length);
            });

          axios
            .post(`${globals.getBackendApi()}/getuserdata`, {
              email: user,
            })
            .then((userdata) => {
              // console.log(userdata.data.results[0].balance);
              setBalance(userdata.data.results[0].balance);
              setLoading(false);
            });
        });
    }
    work()
    return work;
  }, []);
  
 
  return (
    loading?<Loader></Loader>:<Box sx={{ display: 'flex' }}>
      <Header DrawerHeader={DrawerHeader} title={"Dashboard"} logout={logout}></Header>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {/* <Drawer Header /> */}
        <div className="container-fluid p-4">
          <Pageheading title={"Dashboard"}></Pageheading>
        </div>
        <div className='container-fluid row' style={{paddingLeft:"3.5em"}}>
          <div className='col-sm-12 p-4 first-dashboard-row row'>
            <div className='col-sm-9 p-4'>
              <h3 style={{color:"#200A69",fontWeight:500,lineHeight:1.3}}>Welcome Back<br></br> Techie!</h3>
              <p style={{color:"#200A69",fontWeight:200,fontSize:"smaller"}}>You can now start exploring your API. Just Headover to the products section.</p>
              <Link to={'/projects'}>
                <button className='btn btn-primary p-2 btn-purple'>Go Now</button>
              </Link>
            </div>
            <div className='col-sm-3'>
              <img width={'100%'} src={blocks.default}></img>
            </div>
          </div>
        </div>

        <div className='container-fluid margin-0-auto row'>

          <div className='col-sm-3 p-4'>
            <div className='dashboard-box text-center project-box'>
              <p style={{color:"white"}}>No. of ongoing projects</p>
              <h1 style={{color:"white"}}>{projectCount}</h1>
              <br></br>
              <Link to='/projects'><small style={{color:"white"}} className='dashboard-box-btn'>View All</small></Link>
            </div>
          </div>

          <div className='col-sm-3 p-4'>
            <div className='dashboard-box text-center expense-box'>
              <p style={{color:"white"}}>Expense <em style={{color:"#fff0f0"}}>(last 30 days)</em></p>
              <h1 style={{color:"white"}}>{thirstyDaysAmount}</h1>
              <br></br>
              <Link to='/billing'><small style={{color:"white"}} className='dashboard-box-btn'>View Report</small></Link>
            </div>
          </div>

          <div className='col-sm-6 p-4'>
            <div className='dashboard-box text-center balance-box'>
              <p style={{color:"white"}}>Current Balance</p>
              <h1 style={{color:"white"}}>{balance}</h1>
              <br></br>
              <Link to='/projects'><small style={{color:"white",width:"100%"}} className='dashboard-box-btn'>Add Balance</small></Link>
            </div>
          </div>

        </div>
      </Box>
    </Box>
  );
}
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import Globals from "../../Globals";

const Getallblocks = () => {
  const globals = new Globals();
  const [value, setValue] = useState(0);
  const [nodecode, setNodecode] = useState(`
var clientSecret = 'Your Client Secret id from the project page';
var projectName = 'Name of the project.';
var response= await fetch(\`${globals.getBackendApi()}/getblocks/?clientSecret=\${clientSecret}&projectName=\${projectName}\`);
console.log(response);
var data= await response.json();
console.log(data);`);

const [python, setPython] = useState(`
import requests
clientSecret = "Your Client Secret id from the project page"
projectName = "Name of the project."
x = requests.get("${globals.getBackendApi()}//getblocks/?clientSecret="+clientSecret+"&projectName="+projectName)
data = eval(x.content)
print(data)
`)

const [axios, setAxios] = useState(`
var clientSecret = 'Your Client Secret id from the project page';
var projectName = 'Name of the project.';
axios
    .get(\`${globals.getBackendApi()}/getblocks/?clientSecret=\${clientSecret}&projectName=\${projectName}\`)
    .then((res) => {
      console.log(res);
    });
`)

  const [javaCode, setJavaCode] = useState(`
String clientSecret = "Your Client Secret id from the project page";
String projectName = "Name of the project.";
String GET_URL = "${globals.getBackendApi()}//getblocks/?clientSecret="+clientSecret+"&projectName="+projectName;
URL obj = new URL(GET_URL);
HttpURLConnection con = (HttpURLConnection) obj.openConnection();
con.setRequestMethod("GET");
int responseCode = con.getResponseCode();
if (responseCode == HttpURLConnection.HTTP_OK) {
BufferedReader in = new BufferedReader(new InputStreamReader(con.getInputStream()));
String inputLine;
StringBuffer response = new StringBuffer();
while ((inputLine = in.readLine()) != null) {
  response.append(inputLine);
}
in.close();

System.out.println(response);
} 
  `);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div className="step container get-api">
      <h4>3. Integrate into your code.</h4>
      <div className="px-4">
        <h5>
          <span className="get-span">GET</span> Retreive all blocks
        </h5>
      </div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Java Script" />
            <Tab label="Axios - JS" />
            <Tab label="Python" />
            <Tab label="Java" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Editor
            value={nodecode}
            onValueChange={(code) => {}}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
        <Editor
            value={axios}
            onValueChange={(code) => {}}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
        <Editor
            value={python}
            onValueChange={(code) => {}}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Editor
            value={javaCode}
            onValueChange={(code) => {}}
            highlight={(code) => highlight(code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
            }}
          />
        </TabPanel>
      </Box>
    </div>
  );
};

export default Getallblocks;

import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const Addbalance = () => {
  const [cookies, setCoookie, removeCookie] = useCookies(["user"]);
  var email = cookies.email;
  // console.log(cookies.email);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [billingName, setBillingName] = useState("")
  const [billingAmount, setBillingAmount] = useState("")
  const [phone, setPhone] = useState("")
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  return (
    <div>
      {/* <Link
        to={`https://windikate.com/blockchainforall/payment.php?fname=Ashutosh&email=ashutoshsrivastava9897@gmail.com&phone=8126134565&bill=200`}
      >
        <Button >Add Balance</Button>
      </Link> */}
     

      <Button onClick={handleOpen} variant={"outlined"}>Add Balance</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Add To Balance"
        aria-describedby="Add Amount to balance"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add To Balance
          </Typography>
          <br></br>
          <TextField required label="Billing Name" style={{width:"100%"}} variant="outlined"  onChange={(e)=>{setBillingName(e.target.value)}}/>
          <br></br>
          <br></br>
          <TextField required label="Billing Amount" style={{width:"100%"}} variant="outlined"  onChange={(e)=>{setBillingAmount(e.target.value)}} type="number"
          InputLabelProps={{
            shrink: true,
          }}/>
          <br></br>
          <br></br>
          <TextField required label="Phone" style={{width:"100%"}} variant="outlined"  onChange={(e)=>{if(parseInt(e.target.value)!=NaN){setPhone(e.target.value)}}}/>
          <br></br>
          <br></br>
          <div className="text-center">
            <Button variant="outlined">ADD</Button>
          </div>
        </Box>
      </Modal>
      
    </div>
  );
};

export default Addbalance;

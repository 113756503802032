import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import Globals from "../Globals";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
// import { useDispatch } from "react-redux";
// import { actionCreators } from "../state";
// import { bindActionCreators } from "redux";
import Loader from "../components/Loader";
import Input from "@mui/joy/Input";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { Alert, Button, Snackbar } from "@mui/material";
import { useCookies } from "react-cookie";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Profile({ logout }) {
  const globals = new Globals();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [usemode, setUsemode] = useState("TEST MODE");
  const [cookies, setCoookie, removeCookie] = useCookies(["user"]);
  const user = cookies.email;
  console.log("profile.js:", user);
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setuid] = useState("");
  const [pass, setPass] = useState("");
  const [phone, setPhone] = useState("");

  // const dispatch = useDispatch();
  // const { changeColor, changeMode } = bindActionCreators(
  //   actionCreators,
  //   dispatch
  // );

  useEffect(() => {
    function work() {
      console.log("profile.js: in the use effect", user);
      axios
        .post(`${globals.getBackendApi()}/getuserdata`, {
          email: user,
        })
        .then((res) => {
          setLoading(false);
          setUserData(res.data.results[0]);
          console.log("profile.js: line 162", res.data.results[0]);
          setUsemode(res.data.results[0].usemode);
          setFname(res.data.results[0].fname);
          setPass(res.data.results[0].pass);
          setEmail(res.data.results[0].email);

          setPhone(res.data.results[0].phone);
          // changeMode(res.data.results[0].usemode);
        });
    }
    work();
    return work;
  }, []);

  const handleUpdate = () => {
    axios
      .post(`${globals.getBackendApi()}/updateUserDetails`, {
        email: user,
        fname: fname,
        pass: pass,
        phone: phone,
      })
      .then((res) => {
        // console.log(res);
        setmsg("Details Updated");
        setmsgbox({ open: true });
      });
  };

  const [passwordType, setPasswordType] = useState("password");
  const changePasswordVisibility = () => {
    if (passwordType == "password") setPasswordType("text");
    else setPasswordType("password");
  };
  const handleModeChange = (event) => {
    // console.log(event.target.checked);

    if (event.target.checked) {
      axios
        .post(`${globals.getBackendApi()}/setusemode`, {
          email: user,
          mode: "PRODUCTION MODE",
        })
        .then((res) => {
          // console.log(res);
        });
      setUsemode("PRODUCTION MODE");
      // changeMode("PRODUCTION MODE");
      // changeColor(globals.getProductionModeColor());
    } else {
      axios
        .post(`${globals.getBackendApi()}/setusemode`, {
          email: user,
          mode: "TEST MODE",
        })
        .then((res) => {
          // console.log(res);
        });
      setUsemode("TEST MODE");
      // changeMode("TEST MODE");
      // changeColor(globals.getTestModeColor());
    }
  };

  const [msg, setmsg] = useState("No error");
  const [msgbox, setmsgbox] = useState({ open: false });

  const msgClose = () => {
    setmsgbox({ open: false });
  };

  if (loading) {
    return <Loader></Loader>;
  } else {
    console.log(userData);
    return (
      <Box sx={{ display: "flex" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={msgbox.open}
          onClose={msgClose}
          message={msg}
        >
          <Alert onClose={msgClose} severity="success" sx={{ width: "100%" }}>
            {msg}
          </Alert>
        </Snackbar>

        <Header
          DrawerHeader={DrawerHeader}
          title={"Profile"}
          logout={logout}
        ></Header>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Typography variant="h4">User Info</Typography>
          <br></br>
          <div className="container-fluid row">
            <div className="col-sm-6">
              <div className="row p-2 my-2">
                <div className="col-sm-4">
                  <Typography>Name</Typography>
                </div>
                <div className="col-sm-8">
                  <Input
                    type="text"
                    className="form-control"
                    value={fname}
                    onChange={(e) => {
                      setFname(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row p-2 my-2">
                <div className="col-sm-4">
                  <Typography>Email</Typography>
                </div>
                <div className="col-sm-8">
                  <Input type="text" className="form-control" value={email} />
                </div>
              </div>

              <div className="row p-2 my-2">
                <div className="col-sm-4">
                  <Typography>User Id</Typography>
                </div>
                <div className="col-sm-8">
                  <Input
                    type="text"
                    className="form-control"
                    value={userData.userid}
                  />
                </div>
              </div>

              <div className="row p-2 my-2">
                <div className="col-sm-4">
                  <Typography>Password</Typography>
                </div>
                <div className="col-sm-8">
                  <Input
                    type={passwordType}
                    className="form-control"
                    value={pass}
                    onChange={(e) => {
                      setPass(e.target.value);
                    }}
                    endDecorator={
                      passwordType == "password" ? (
                        <VisibilityIcon
                          onClick={() => {
                            changePasswordVisibility();
                          }}
                          className="cursor-pointer"
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() => {
                            changePasswordVisibility();
                          }}
                          className="cursor-pointer"
                        />
                      )
                    }
                  />
                </div>
              </div>

              <div className="row p-2 my-2">
                <div className="col-sm-4">
                  <Typography>Phone</Typography>
                </div>
                <div className="col-sm-8">
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="(optional)"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="container justify-content-right">
                <button
                  className="btn btn-main"
                  onClick={() => {
                    handleUpdate();
                  }}
                >
                  Save
                </button>
              </div>
            </div>

            <div className="col-sm-6">
              <FormGroup>
                <FormControlLabel
                  control={
                    usemode === "TEST MODE" ? (
                      <IOSSwitch sx={{ m: 1 }} />
                    ) : (
                      <IOSSwitch sx={{ m: 1 }} defaultChecked />
                    )
                  }
                  label={usemode.toLowerCase() + " is on"}
                  onChange={handleModeChange}
                />
              </FormGroup>
            </div>
          </div>
        </Box>
      </Box>
    );
  }
}

import { combineReducers } from "redux";
import colorReducer from "./colorReducer";
import modeReducer from "./modeReducer";
import userReducer from "./userReducer";

const reducer = combineReducers({
    mode: modeReducer,
    color: colorReducer,
    cuser: userReducer
})

export default reducer
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Header from "../components/Header";
import { DataGrid } from "@mui/x-data-grid";
import Globals from "../Globals";
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { Button } from "@mui/material";
import Addbalance from "../components/Addbalance";
import Pageheading from "../components/Pageheading";
import { useCookies } from "react-cookie";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  { field: "type", headerName: "Type", width: 150 },
  { field: "hashid", headerName: "Hash ID", width: 150 },
  { field: "time", headerName: "Time", width: 390 },
  {
    field: "bill",
    headerName: "Amount",
    type: "number",
    width: 150,
  },
];

export default function Billing({ logout }) {
  const globals = new Globals();
  const [cookies, setCoookie, removeCookie] = useCookies(["user"]);
  
  const user = cookies.email;
  const [rows, setRows] = useState([]);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [thirstyDaysAmount, setThirstyDaysAmount] = useState(0);
  useEffect(() => {
    function work(){
      // /getbilling
      axios
        .post(`${globals.getBackendApi()}/getbilling`, {
          email: user,
        })
        .then((res) => {
          console.log(res);
          var tda = 0;
          res.data.map((value, index) => {
            value.time = new Date(parseInt(value.time));
            if ((new Date() - value.time) / 8.64e7 < 30) {
              tda += parseFloat(value.bill);
            }
          });
          setThirstyDaysAmount(tda);
          setRows(res.data);
          // console.log(`${globals.getBackendApi()}/getuserdata`)
          axios
            .post(`${globals.getBackendApi()}/getuserdata`, {
              email: user,
            })
            .then((userdata) => {
              console.log(userdata.data.results[0].balance);
              setBalance(userdata.data.results[0].balance);
              setLoading(false);
            });
        });
    }
    work()
    return work;
  }, []);

  if (loading) {
    return <Loader></Loader>;
  } else {
    return (
      <Box sx={{ display: "flex" }}>
        <Header
          DrawerHeader={DrawerHeader}
          title={"Billing"}
          logout={logout}
        ></Header>
        {/* <Pageheading title={"Billing Section"}></Pageheading> */}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <div style={{ height: 400, width: "100%" }}>
            <div className="container-fluid row p-4 my-2">
              <div className="col-sm-6 row">
                <div className="col-sm-4"><span style={{fontWeight:700}}>Amount Spent in Last 30 Days </span><br></br> {thirstyDaysAmount}</div>
                {/* <div className="col-sm-7"></div> */}
              </div>
              <div className="col-sm-6 row">
                <div className="col-sm-6">
                  <div className="col-sm-6"><span style={{fontWeight:700}}>Current Balance</span>  {balance}</div>
                </div>
                <div className="col-sm-6 row">
                  <Addbalance></Addbalance>
                </div>
              </div>
            </div>
            <DataGrid
              rows={rows}
              columns={columns}
              paginationModel={{ page: 0, pageSize: 5 }}
            />
          </div>
        </Box>
      </Box>
    );
  }
}

export default class Globals{
    constructor(){}

    getBackendApi(){
        return "https://windikate.tech:8080";
    }

    getApiKey(){
        return "certipass";
    }

    getProductionModeColor(){
        return "#000"; // black color
        // return "#1976d2"; //blue color
    }
    getTestModeColor(){
        return "#65c466";
    }
    
    getPrimaryColor(){
        return "#7047EB";
    }

    getCreateMultiplier(){
        return 0.03;
    }

    getEditMultiplier(){
        return 0.02;
    }

    createAmount(usage){
        return this.getCreateMultiplier()*usage;
    }
 
    editAmount(usage){
        return this.getEditMultiplier()*usage;
    }

}

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
// import Link from '@mui/material/Link';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GoogleIcon from '@mui/icons-material/Google';
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import Globals from "../Globals";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Alert, Snackbar } from "@mui/material";
import Transparentheader from "../components/Transparentheader";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://Windikate.com/">
        Windikate Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Signin({ login }) {
  const [cookies, setCoookie] = useCookies(["user"]);
  const navigate = useNavigate();

  const globals = new Globals();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    axios
      .post(`${globals.getBackendApi()}/validateuser`, {
        email: data.get("email"),
        pass: data.get("password"),
      })
      .then((res) => {
        if (res.data.length > 0) {
          setCoookie("email", data.get("email"), { sameSite: false });
          login(data.get("email"));
          console.log(res);
        } else {
          setErrorMessage("Wrong Credentials");
          setErrorbox({ open: true });
        }
      });
  };
  const [errorMessage, setErrorMessage] = React.useState("No error");
  const [errorbox, setErrorbox] = React.useState({ open: false });
  
  const errorboxClose = () => {
    setErrorbox({ open: false });
  };
  
  

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorbox.open}
        onClose={errorboxClose}
        message={errorMessage}
      >
        <Alert onClose={errorboxClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
        {/* <Transparentheader></Transparentheader> */}
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className="verticalCenter"
          >

            <h2 style={{fontSize:36,fontWeight:700}}>Hey Techie</h2>
            
            <Typography component="p" variant="p" className="my-2">
            Welcome back! please enter your details.
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Enter your ID"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Enter Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <div className="text-align-right forgot-password">Forgot password?</div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="login-btn"
                style={{background:"#7047EB"}}
              >
                Sign In
              </Button>
              
              {/* <button className="btn btn-login-with-google container-fluid">
                <GoogleIcon className="mx-2"></GoogleIcon>
                Sign in With Google
              </button> */}
              <div className="py-4 text-center">
                
                <Grid item style={{textAlign:"center"}}>
                  {"Don't have an account? "}
                  <Link to={"/register"} style={{color: globals.getPrimaryColor(),cursor: "pointer"}} variant="body2">
                    {"Sign Up"}
                  </Link>
                </Grid>
              </div>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={false} sm={4} md={6}>
          <div style={{ width: "100%", height: "100vh", background: globals.getPrimaryColor()}}>
            
          </div>
          
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Header from "../components/Header";
import Dialogbox from "../components/Dialogbox";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import Globals from "../Globals";
import axios from "axios";
import ProjectCard from "../components/ProjectCard";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Projects({ logout }) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cookies, setCoookie, removeCookie] = useCookies(["user"]);
  
  const global = new Globals();
  const user = cookies.email;
  // const user = 'abc'
  
  useEffect(() => {
    // const url = `${global.getBackendApi()}/getprojects`;
    // console.log(url)
    function func()
    {
      console.log("from selector",user)
      axios.post(`${global.getBackendApi()}/getprojects`, {
        manager: user
      }).then((res) => {
          // console.log(cookies.email)
          console.log("project.js: useEffect: ",res)
          setLoading(false);
          setProjects(res.data);
        });
    }
    func()
    return func;
  }, []);
  
  useEffect(() => {
    const url = `${global.getBackendApi()}/getprojects`;
    return () => {
      axios
        .post(url, {
          manager: user,
        })
        .then((res) => {
          setLoading(false);
          setProjects(res.data);
        });
    };
  }, [loading]);
  
    return (
      loading?<Loader></Loader>:<Box sx={{ display: "flex" }}>
        <Header
          DrawerHeader={DrawerHeader}
          title={"Projects"}
          logout={logout}
        ></Header>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <div>
            <Dialogbox setLoading={setLoading}></Dialogbox>
            <div className="container-fluid row">
              { projects.map((value, index) => {
                  return ( <ProjectCard key={index} project={value} setLoading={setLoading}/>)
              }) }
            </div>
          </div>
        </Box>
      </Box>
    );
  
}

import "./App.css";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Signin from "./pages/Signin";
import Register from "./pages/Register";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Profile from "./pages/Profile";
import Party from "./pages/Party";
import Projects from "./pages/Projects";
import Docs from "./pages/Documentation";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "./state";
import Globals from "./Globals";
import axios from "axios";
import Billing from "./pages/Billing";
import Projectview from "./pages/Projectview";

function App() {
  const [cookies, setCoookie, removeCookie] = useCookies(["user"]);
  const [user, setuser] = useState({ loggedin: false });
  const global = new Globals();
  
  const logout = () => {
    removeCookie("email", { path: "/" });
    setuser({ loggedin: false });
  };
  const dispatch = useDispatch();
  const { changeColor, changeMode, changeUser } = bindActionCreators(actionCreators, dispatch);
  const login = (email) => {
    setuser({ loggedin: true, email: email });
    
  };

  useEffect(() => {
    return () => {
      console.log("app.js: useEffect:",cookies.email)
      if (cookies.email !== undefined) {
        setuser({ loggedin: true, email: cookies.email });
        changeUser('ashu');
        axios.post(`${global.getBackendApi()}/getusemode`,{
          "email":cookies.email
        }).then((res)=>{
          console.log(res.data.results[0].usemode)
          changeMode(res.data.results[0].usemode)
          if (res.data.results[0].usemode === "TEST MODE") {
            changeColor(global.getTestModeColor());
          } else {
            changeColor(global.getProductionModeColor());
          }
        })
      } else {
        setuser({ loggedin: false });
      }
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            exact
            element={
              user.email === undefined ? (
                <Signin login={login}></Signin>
              ) : (
                <Home logout={logout} user={cookies.email} />
              )
            }
          />
          <Route
            path="/profile"
            exact
            element={
              user.email === undefined ? (
                <Signin login={login}></Signin>
              ) : (
                <Profile logout={logout} user={cookies.email} />
              )
            }
          />
          <Route
            path="/party"
            exact
            element={
              user.email === undefined ? (
                <Signin login={login}></Signin>
              ) : (
                <Party logout={logout} user={cookies.email} />
              )
            }
          />
          <Route
            path="/docs"
            exact
            element={
              user.email === undefined ? (
                <Signin login={login}></Signin>
              ) : (
                <Docs logout={logout} user={cookies.email} />
              )
            }
          />
          <Route
            path="/billing"
            exact
            element={
              user.email === undefined ? (
                <Signin login={login}></Signin>
              ) : (
                <Billing logout={logout} user={cookies.email} />
              )
            }
          />
          <Route
            path="/projects"
            exact
            element={
              user.email === undefined ? (
                <Signin login={login}></Signin>
              ) : (
                <Projects logout={logout} />
              )
            }
          />
          
          <Route
            path="/projects/:project"
            exact
            element={
              user.email === undefined ? (
                <Signin login={login}></Signin>
              ) : (
                <Projectview logout={logout} user={cookies.email} />
              )
            }
          />

          <Route
            path="/signin"
            exact
            element={
              user.email === undefined ? (
                <Signin login={login}></Signin>
                
              ) : (
                <Home logout={logout} user={cookies.email} />
              )
            }
          />
          
          <Route
            path="/home"
            exact
            element={
              user.email === undefined ? (
                <Signin login={login}></Signin>
              ) : (
                <Home logout={logout} user={cookies.email} />
              )
            }
          />
          <Route
            path="/register"
            exact
            element={
              user.email === undefined ? (
                <Register login={login}></Register>
              ) : (
                <Home logout={logout} user={cookies.email} />
              )
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
